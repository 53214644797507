import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import CustomScrollbars from "util/CustomScrollbars";

class SidenavContent extends Component {
  componentDidMount() {
    const { history } = this.props;
    const that = this;
    const pathname = `#${history.location.pathname}`; // get current path

    const subMenuLi = document.querySelectorAll(".sub-menu > li");
    for (let i = 0; i < subMenuLi.length; i++) {
      subMenuLi[i].onclick = function(event) {
        event.stopPropagation();
      };
    }

    const menuLi = document.getElementsByClassName("menu");
    for (let i = 0; i < menuLi.length; i++) {
      menuLi[i].onclick = function(event) {
        for (let j = 0; j < menuLi.length; j++) {
          const parentLi = that.closest(this, "li");
          if (
            menuLi[j] !== this &&
            (parentLi === null || !parentLi.classList.contains("open"))
          ) {
            menuLi[j].classList.remove("open");
          }
        }
        this.classList.toggle("open");
        event.stopPropagation();
      };
    }

    const activeLi = document.querySelector('a[href="' + pathname + '"]'); // select current a element
    try {
      const activeNav = this.closest(activeLi, "ul"); // select closest ul
      if (activeNav.classList.contains("sub-menu")) {
        this.closest(activeNav, "li").classList.add("open");
      } else {
        this.closest(activeLi, "li").classList.add("open");
      }
    } catch (error) {}
  }

  closest(el, selector) {
    try {
      let matchesFn;
      // find vendor prefix
      [
        "matches",
        "webkitMatchesSelector",
        "mozMatchesSelector",
        "msMatchesSelector",
        "oMatchesSelector",
      ].some(function(fn) {
        if (typeof document.body[fn] == "function") {
          matchesFn = fn;
          return true;
        }
        return false;
      });

      let parent;

      // traverse parents
      while (el) {
        parent = el.parentElement;
        if (parent && parent[matchesFn](selector)) {
          return parent;
        }
        el = parent;
      }
    } catch (e) {}

    return null;
  }

  render() {
    return (
      <CustomScrollbars className=" scrollbar">
        <ul className="nav-menu">
          <li className="menu no-arrow">
            <NavLink to="/app/dashboard/index">
              <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
              <span className="nav-text">Dashboard</span>
            </NavLink>
          </li>

          <li className="menu no-arrow">
            <NavLink to="/app/customers">
              <i className="zmdi zmdi-accounts-alt zmdi-hc-fw" />
              <span className="nav-text">Customers </span>
            </NavLink>
          </li>
          <li className="menu no-arrow">
            <NavLink to="/app/drivers">
              <i className="zmdi zmdi-local-taxi zmdi-hc-fw" />
              <span className="nav-text">Drivers </span>
            </NavLink>
          </li>
          <li className="menu no-arrow">
            <NavLink to="/app/canceldrivers">
              <i className="zmdi zmdi-accounts-alt zmdi-hc-fw" />
              <span className="nav-text"> Cancelled List </span>
            </NavLink>
          </li>
          {/* <li className="menu no-arrow">
            <NavLink to="/app/cancelcustomers">
              <i className="zmdi zmdi-accounts-alt zmdi-hc-fw" />
              <span className="nav-text"> Cancelled Customers </span>
            </NavLink>
          </li> */}
          <li className="menu no-arrow">
            <NavLink to="/app/dispatchers">
              <i className="zmdi zmdi-account-box-o zmdi-hc-fw" />
              <span className="nav-text">Dispatchers </span>
            </NavLink>
          </li>
          <li className="menu no-arrow">
            <NavLink to="/app/car-types">
              <i className="zmdi zmdi-copy zmdi-hc-fw" />
              <span className="nav-text">Car Types </span>
            </NavLink>
          </li>

          <li className="menu no-arrow">
            <NavLink to="/app/trips">
              <i className="zmdi zmdi-swap-vertical-circle zmdi-hc-fw" />
              <span className="nav-text">Trips </span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/app/promotions">
              <i className="zmdi zmdi-labels zmdi-hc-fw" />
              <span className="nav-text">Banner</span>
            </NavLink>
          </li>
          <li className="menu no-arrow">
            <NavLink to="/app/promo-codes">
              <i className="zmdi zmdi-label zmdi-hc-fw" />
              <span className="nav-text">Promo Codes</span>
            </NavLink>
          </li>
          <li className="menu no-arrow">
            <NavLink to="/app/additional-charges">
            <i className="zmdi zmdi-card-travel"></i>
              <span className="nav-text">Additional Charges</span>
            </NavLink>
          </li>
          <li className="menu no-arrow">
            <NavLink to="/app/contract">
            <i className="zmdi zmdi-file"></i>

              <span className="nav-text">Contract</span>
            </NavLink>
          </li>

          <li className="menu no-arrow">
            <NavLink to="/app/contentPages">
              <i className="zmdi zmdi-file-text zmdi-hc-fw" />
              <span className="nav-text">Content Pages</span>
            </NavLink>
          </li>
          <li className="menu no-arrow">
            <NavLink to="/app/faq">
              <i className="zmdi zmdi-comments zmdi-hc-fw" />
              <span className="nav-text">Faq</span>
            </NavLink>
          </li>
          <li className="menu no-arrow">
            <NavLink to="/app/notification">
              <i className="zmdi zmdi-notifications-active zmdi-hc-fw" />
              <span className="nav-text">Push Notification</span>
            </NavLink>
          </li>
          <li className="menu collapse-box">
            <Button href="javascript:void(0)" className="cbutton">
              <i className="zmdi zmdi-settings zmdi-hc-fw" />
              <span className="nav-text">Settings</span>
            </Button>
            <ul className="sub-menu">
              <li className="menu no-arrow">
                <NavLink className="prepend-icon cbutton" to="/app/users/list">
                  <i className="zmdi zmdi-account" />
                  <span className="nav-text">Admin Settings</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="prepend-icon cbutton"
                  to="/app/settings/basic"
                >
                  <i class="zmdi zmdi-album"></i>

                  <span className="nav-text">Basic Settings</span>
                </NavLink>
              </li>

              <li>
                <NavLink
                  className="prepend-icon cbutton"
                  to="/app/settings/installation"
                >
                  <i class="zmdi zmdi-remote-control"></i>

                  <span className="nav-text">Installation Settings</span>
                </NavLink>
              </li>

              <li>
                <NavLink
                  className="prepend-icon cbutton"
                  to="/app/settings/mail"
                >
                  <i class="zmdi zmdi-email"></i>

                  <span className="nav-text">Mail templates</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="prepend-icon cbutton"
                  to="/app/settings/sms"
                >
                  <i class="zmdi zmdi-comment-text"></i>

                  <span className="nav-text">SMS Templates</span>
                </NavLink>
              </li>
            </ul>
          </li>
        </ul>
      </CustomScrollbars>
    );
  }
}

export default withRouter(SidenavContent);
